document.addEventListener("DOMContentLoaded", () => {
  const tabs: HTMLDivElement = document.querySelector(".tabs")!;
  const tabButtons: NodeListOf<HTMLButtonElement> =
    tabs?.querySelectorAll('[role="tab"]');
  const tabPanels: NodeListOf<HTMLDivElement> =
    tabs?.querySelectorAll('[role="tabpanel"]');

  const handleTabClick = (event: MouseEvent) => {
    // hide all tab panels
    tabPanels?.forEach((panel) => {
      panel.hidden = true;
    });
    // mark all tabs as unselected
    tabButtons?.forEach((tab) => {
      tab.setAttribute("aria-selected", "false");
    });
    // mark the clicked tab as selected
    const target = event.currentTarget as HTMLButtonElement;
    target.setAttribute("aria-selected", "true");
    // find the associated tab panel and show it
    const { id } = target;
    const tabPanel: HTMLDivElement = tabs.querySelector(
      `[aria-labelledby="${id}"]`,
    )!;
    tabPanel.hidden = false;
  };

  tabButtons?.forEach((button) =>
    button.addEventListener("click", handleTabClick),
  );
});
